<template>
    <modal class="departmentSelectModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">选择适用门店
            <div class="search-box">
                <div class="search"><input type="text" v-model="searchText" placeholder="输入门店名称检索"></div>
                <div class="bnt-search">搜索</div>
            </div>
        </div>
        <div class="modal-body">
            <div class="panel" >
                <div class="panel-heading">
                    <div class="nav-box" v-scroll-anime="{up:'nav-up',down:'nav-down',fx:'x',liClass:'.nav-li',page:'navPage',isNavFirstSticky:true}">
                        <div class="nav-li " :class="{selected:navIndex==-1}" @click="navIndex=-1" >全部</div>
                        <div class="nav-li" :class="{selected:navIndex==item.Brand_Autoid}" v-for="(item) in brandList" :key="item" @click="navIndex=item.Brand_Autoid">{{item.Brand_Name}}</div>
                    </div>
                    <div class="float-right page-box"><span class="last-page iconfont icon-fangxiangquan-left" ref="nav-up"></span>
                        <span class="page-number">{{navPage.pageIndex}}/{{navPage.pageSize}}页</span>
                        <span class="next-page iconfont icon-fangxiangquan-right" ref="nav-down" ></span>
                    </div>
                </div>
                <div class="panel-body" v-scroll-anime="{up:'left-order-up',down:'left-order-down',liClass:'.item-li',marginH:12,paddingH:24,isRow:false}">
                    <div class="item-li" :class="{selected:item._selected}" @click="onDepartment(item)"
                        v-for="item in getDoorList()" :key="item">
                        <div class="name inlineNowrap2"><span>{{item.Door_Name}}</span></div>
                       <i class="iconfont icon-gou1"></i>
                    </div>
                </div>
                <div class="item-operation">
                    <div class="selectChecked" @click="onAll()"><span class="iconfont icon-iconoption" :class="{'icon-iconoption1':isAllSelect}"></span>全选 </div>
                    <div class="float-right">
                        <div class="bnt-fangxiang" ref="left-order-up"><i class="iconfont icon-fangxiang-up"></i></div>
                        <div class="bnt-fangxiang" ref="left-order-down"><i class="iconfont icon-fangxiang-down"></i></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
    </modal>
</template>

<script>

/**选择适用门店*/
export default {
    name:"departmentSelectModel",
    emits:["closeModel","confirm"],
    props:{
        isShow:Boolean,
        isAllDoor:Number,
        doorAutoIds:{
            default:[]
        }
    },
    data(){
        return {
            /**机构分类 */
            navPage:{
                pageIndex:1,
                pageSize:1
            },
            /**选中类别 */
            navIndex:-1,
            /**品牌数据 */
            brandList:[],
            /**门店数据 */
            doorList:[],
            /**搜索字段 */
            searchText:'',
            //是否全选
            isAllSelect:false
        }
    },
    mounted(){
        this.loadData();
    }, 
    watch: {
        isShow(newVal) {
            if(newVal){
                this.init();
            }
        },
        searchText(newVal){
            if(newVal!=""){
                this.navIndex=-1;
            }
        }
    },
    methods:{
        /**加载CRM门店信息 */
        loadData(){
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            /**获取CRM门店信息 */
            this.$httpAES.post("Bestech.CloudPos.GetCrmBaseDoors",{
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.doorList=d.ResponseBody||[];
                    this.brandList=[];
                    let brand={};
                    (this.doorList||[]).map((item)=>{
                        if(!brand[item.Brand_Autoid]){
                            brand[item.Brand_Autoid]=true;
                            this.brandList.push({Brand_Autoid:item.Brand_Autoid,Brand_Code:item.Brand_Code,Brand_Name:item.Brand_Name});
                        }
                    })
                    this.init();
                }else{
                    this.doorList=[];
                    this.brandList=[];
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('加载门店信息失败：'+e,);
                console.log('加载门店信息失败：'+e);
            })
        },
        init(){
            this.isAllSelect=false;
            this.navIndex=-1;
            if(this.isAllDoor==1){
                this.onAll();
            }else{
                (this.doorList||[]).map(item=>{
                    if((this.doorAutoIds||[]).findIndex(id=>id==item.Door_Autoid)>=0){
                        item._selected=true;
                    }else{
                        item._selected=false;
                    }
                })
            }
            
        },
        hide(){
            this.$emit("closeModel");
        },
        getDoorList(){
            let data=[];
            if(this.navIndex==-1){
                data= this.doorList;
            }else{
                data=(this.doorList||[]).filter(item=>item.Brand_Autoid==this.navIndex);
            }
            let searchText=this.searchText;
            if(searchText){
                return (data||[]).filter(item=> 
                    (item.Door_Name||"").toLowerCase().indexOf(searchText)>=0 
                );
            }
            return data;
        },
        /**全选 */
        onAll(){
            this.isAllSelect=!this.isAllSelect;
            (this.doorList||[]).map(item=>{
                item._selected=this.isAllSelect;
            })
        },
        onDepartment(item){
            item._selected=!item._selected;
            if(!item._selected){
                this.isAllSelect=false;
            }
        },
        /**确定和回车事件 */
        confirm(){ 
            let selectList=[];
            (this.doorList).map(item=>{
                if(item._selected){
                    selectList.push(item.Door_Autoid);
                }
            })
            if(selectList.length==0){
                this.$message.warning('请先选择门店信息!');
                return;
            }
            this.$emit("confirm",this.isAllSelect,selectList);
        }
    }
}
</script>

<style lang="sass">
@import "./departmentSelectModel.scss";
</style>